import React, { lazy, Suspense } from 'react'
import styled from 'styled-components';

import img1 from '../../assets/Nfts/collection1/YK-PP.png';
import img2 from '../../assets/Nfts/collection1/STEEL-PP.png';
import img3 from '../../assets/Nfts/collection1/549.png';
import img4 from '../../assets/Nfts/collection1/PARIZOO-PP.jpg';
import img5 from '../../assets/Nfts/collection1/853.png';
import Loading from '../Loading';
// import ConfettiComponent from '../Confetti';

const ConfettiComponent = lazy(() => import("../Confetti"));


const Section = styled.section`
min-height: 100vh;
width: 100vw;
background-color: ${props => props.theme.body};
position: relative;
overflow: hidden;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontxl};

}
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em){
width: 80%;
}
@media (max-width: 48em){
width: 90%;
justify-content: center;
}
`

const Item = styled.div`
width: calc(20rem - 4vw);
padding: 1rem 0;
color: ${props => props.theme.body};
margin: 2rem 1rem;
position: relative;
z-index:5;

backdrop-filter: blur(4px);

border: 2px solid ${props => props.theme.text};
border-radius: 20px;


@media (max-width: 30em){
width: 70vw;
}

`

const ImageContainer = styled.div`
position: relative;
border-radius: 50%;
border: 4px dashed transparent;
margin: 30px;
transition: padding .3s;

border-radius: 10em;
cursor: pointer;

&:hover{
  padding: 20px;
  border: 4px dashed white;
}

img{
  width: 100%;
  height: auto;
  border-radius: 50%;

}
`

const Name = styled.h2`
font-size: ${props => props.theme.fontlg};
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
color: ${props => props.theme.text};
margin-top: 1rem;
`

const Position = styled.h2`
font-size: ${props => props.theme.fontmd};
display: flex;
align-items: center;
justify-content: center;

color: ${props => `rgba(${props.theme.textRgba},0.9)`};
font-weight:400;
`

const MemberComponent = ({img, name=" ",position=" "}) => {

  return(
    <Item>
      <ImageContainer>
        <img width={500} height={400}  src={img} alt={name} />
      </ImageContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  )
}


const Team = () => {
  return (
    <Section id="team">
    <Suspense fallback={<Loading />}>
    <ConfettiComponent  /> </Suspense>
      <Title>Team</Title>
      <Container>
        <MemberComponent img={img1}  name="Yk The 🗿" position="Founder" />
        <MemberComponent img={img2}  name="Steel the 🗿" position="Co-Founder" />
        <MemberComponent img={img3}  name="THE_Mti 🗿 " position="Designer" />
        <MemberComponent img={img4}  name="Parizoo 🗿" position="Developer" />
        <MemberComponent img={img5}  name="Babayaga 🗿" position="Partner" />

        

      </Container>
    </Section>
  )
}

export default Team